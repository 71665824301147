.app__banner_img {
  text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 60%;
    padding: 1em 
}
  
  /* .app__banner_img img {
    width: 30%;
  } */

  .app__banner {
    width: 100%;
    display: flex;
    align-items: center;
    background: white;
    padding: 3rem .5rem;
}