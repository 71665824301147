.app__banner_img {
    text-align: center;
      display: block;
      justify-content: center;
      align-items: center;
      margin: auto;
      width: 50%;
   
  }
    
  
.app__banner2 {
      width: 100%;
      display: flex;
      align-items: center;
      background: black;
      padding: 3rem .2rem;
}