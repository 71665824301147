.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: black;
    border: .5px solid black;
    padding: 1rem 2rem;
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.app__navbar-logo img {
    width: 180px;
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: white; */

    list-style: none;
}

.app__navbar-links li {
    margin: 0 1rem;
    cursor: pointer;
}

.app__navbar-links li:hover {
    color: gray;
}

.p__opensans {
    font-family: var(--font-alt);
    color: white;
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 28px;
    font-size: 16px;
  }

  .app__navbar-smallscreen {
    display: none;
  }
  
  .app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: black;
    transition: .5s ease;

    flex-direction: column;
    z-index: 5;
}
.app__navbar-smallscreen-links {
    list-style: none;
}

.app__navbar-smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: rgb(248, 237, 24);
    font-size: 2rem;
    text-align: center;
}

.app__navbar-smallscreen_links li:hover {
    color: white;
}


.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color:rgb(248, 237, 24)t;
    cursor: pointer;

    position: absolute;
    top: 20px;
    right: 20px;
}

@media screen and (max-width: 1150px) {
    .app__navbar-links {
        display: none;
    }

    .app__navbar-smallscreen {
        display: flex;
    }
}

@media screen and (min-width: 2000px) {
    .app__navbar-logo img {
        width: 210px;
    }
}


@media screen and (min-width: 2000px) {
    .app__navbar-logo img {
        width: 210px;
    }
}