.p__opensans {
  font-family: 'Cormorant Garamond', serif;
  color: white;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 28px;
  font-size: 16px;
}

.p__opensans2 {
  font-family: 'Cormorant Garamond', serif;
  color: black;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 28px;
  font-size: 16px;
}
.p__cormorant {
  font-family: 'Cormorant Garamond', serif;
  color: whitesmoke;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 23px;
}

.section__padding {
    padding: 4rem 6rem;
  }
  
.flex__center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 100vh;
}

.app__wrapper_info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app__wrapper_img {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 2rem;
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 70%;
  height: 60%;
}

.app__wrapper_img2 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 2rem;
}

.app__wrapper_img2 img {
  width: 50%;
  height: 70%;
}

.app__wrapper_img3 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 2rem;
}

.app__wrapper_img3 img {
  width: 40%;
  height: 50%;
}

.headtext__cormorant {
  font-family: 'Lora', serif;;
  color: rgb(238, 201, 17);
  font-size: 70px;
  font-weight: 900;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

@media screen and (max-width: 850px) {
  .section__padding {
    padding: 4rem;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  
  .p__cormorant {
    font-size: 21px;
  }

  .p__opensans {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .p__opensans {
    font-size: 12px;
  }

  .p__cormorant {
    font-size: 19px;
  }

  .headtext__cormorant {
    font-size: 45px;
    line-height: 70px;
  }
}


.app__bg2 {
  background-color: black;
 }
