.footer__section {
    width: 100%;
    height: 40px;
    background-color: white;
}
.footer__text {
    color: black;
    font-size: small;
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 60%;
    padding: 1em 
}